import React from 'react'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SlickSlider = ({children, settings, ...rest}) => {

	return (
		<>
		<style>
			{
				`
				.slick-arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 10;
					width: 2rem;
					height: 2rem;
				}

				.slick-arrow.slick-next {
					right: 2%;
				}

				.slick-arrow.slick-next::before {
					font-size: 2rem;
				}

				.slick-arrow.slick-prev::before {
					font-size: 2rem;
				}

				.slick-arrow.slick-prev {
					left: 2%;
				}

				.slick-dots {
					margin-bottom: -8px;
				}
				`
			}
		</style>
		<Slider {...settings}>
			{children}
		</Slider>
		</>
	)

}

export default SlickSlider